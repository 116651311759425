import React, { useEffect } from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Hero from "../components/hero"

export default function Error() {
  return (
    <>
      <Hero
        title="Error 404: Page Not Found."
        bodyText="You appear to have stumbled upon a page that doesn't exist. "
      />
    </>
  )
}
