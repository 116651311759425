import React from "react"
import { Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: "Work Sans",
    fontSize: "2.2rem",
    textAlign: "center",
    color: "#474747",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.2rem",
    },
  },
  bodyText: {
    fontFamily: "Open Sans",
    fontSize: "1.1rem",
    color: "#474747",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
}))

export default function Contact(props) {
  const classes = useStyles()

  return (
    <>
      <div style={{ height: "12vh" }} />
      <Typography className={classes.title}>{props.title}</Typography>
      <Typography className={classes.bodyText}>{props.bodyText}</Typography>
      <div style={{ height: "1vh" }} />
      <hr style={{ width: "5%", border: "1px solid #0072ab" }} />
    </>
  )
}
